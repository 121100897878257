exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-api-mdx": () => import("./../../../src/pages/api.mdx" /* webpackChunkName: "component---src-pages-api-mdx" */),
  "component---src-pages-browser-extension-tsx": () => import("./../../../src/pages/browser-extension.tsx" /* webpackChunkName: "component---src-pages-browser-extension-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mg-[id]-tsx": () => import("./../../../src/pages/mg/[id].tsx" /* webpackChunkName: "component---src-pages-mg-[id]-tsx" */),
  "component---src-pages-q-[id]-tsx": () => import("./../../../src/pages/q/[id].tsx" /* webpackChunkName: "component---src-pages-q-[id]-tsx" */),
  "component---src-pages-status-tsx": () => import("./../../../src/pages/status.tsx" /* webpackChunkName: "component---src-pages-status-tsx" */),
  "component---src-pages-tools-browser-extension-mdx": () => import("./../../../src/pages/tools/browser-extension.mdx" /* webpackChunkName: "component---src-pages-tools-browser-extension-mdx" */),
  "component---src-pages-tools-index-tsx": () => import("./../../../src/pages/tools/index.tsx" /* webpackChunkName: "component---src-pages-tools-index-tsx" */),
  "component---src-pages-tools-telegram-bot-mdx": () => import("./../../../src/pages/tools/telegram-bot.mdx" /* webpackChunkName: "component---src-pages-tools-telegram-bot-mdx" */)
}

